/* eslint-disable */
<template>
  <div
    class="tree-block"
    :draggable="!!isdraggable"
    @dragstart="dragstart($event)"
    @dragend="dragend($event)"
    @contextmenu.stop.prevent="$emit('menu', $event, model)"
  >
    <div
      class="tree-row"
      :data-level="depth"
      :tree-id="model[custom_field.id]"
      :tree-p-id="model[custom_field.parent_id]"
      :class="[
        { 'highlight-row': model.highlight == true },
        { [$style.computer]: model.type === 'computer' },
        {
          [$style.routerLinkActive]:
            $route.params.type === model.type &&
            $route.params.computer === model.id,
        },
      ]"
      :style="{ backgroundColor: model.backgroundColor }"
      @dblclick="toggle"
      @click="$emit('redirect', model)"
    >
      <column
        v-for="(subItem, subIndex) in columns"
        :key="subIndex"
        :class="['align-' + subItem.align, 'colIndex' + subIndex]"
        :field="subItem.field"
        :width="subItem.width"
        :max-width="maxWidth(subItem)"
        :padding="padding(subItem)"
        :flex="subItem.flex"
        :align-self="subItem.alignSelf"
        :border="border"
      >
        <div
          v-if="subItem.type === 'selection'"
          :class="$style.selection"
        >
          <space :depth="depth" />
          <div
            v-if="model[custom_field.lists] && model[custom_field.lists].length"
            :class="$style.expandArea"
            @click="toggle"
          >
            <div
              v-if="model[custom_field.lists].length"
              :class="[
                $style.expandButton,
                { [$style.expanded]: model[custom_field.open] },
              ]"
            />
          </div>
          <div
            v-else
            :class="[$style.expandButton, $style.invisButton]"
          />

          <img
            v-if="model.type === 'folder'"
            :class="$style.iconFolder"
            src="@/assets/images/icons/computer/folder.svg"
          >
          <div
            v-if="model.dang && readOnly"
            :class="[$style.dangerous, dangerousClass]"
            :title="dangerousTitle"
          />
          <select
            v-if="model.dang && !readOnly"
            v-model="model.dang"
            :class="$style.nameInput"
          >
            <option
              v-for="option in dangOptions"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </option>
          </select>
          <div
            v-if="subItem.formatter"
            v-html="subItem.formatter(model)"
          />
          <input
            v-else-if="subItem.field"
            v-model="model[subItem.field]"
            type="text"
            :readonly="readOnly"
            :class="{
              [$style.inactive]: readOnly,
              [$style.nameInput]: model.dang,
              [$style.noParentVncInput]: model.parentId === 0 && model.type !== 'folder' && isLeftGrid,
              [$style.computerInput]: true,
            }"
          >

          <slot
            v-else
            name="selection"
            :row="model"
          />
        </div>

        <div v-else-if="subItem.type === 'checkbox'">
          <input
            v-if="model.isShowCheckbox !== false"
            v-model="model[custom_field.checked]"
            type="checkbox"
            :name="model[custom_field.id]"
            class="checkbox action-item"
            :class="$style.checkbox"
            @click.stop="onCheckboxClick($event, model)"
          >
        </div>

        <div v-else-if="subItem.type === 'action'">
          <div
            v-if="subItem.actions"
            :class="$style.actionImgs"
          >
            <a
              v-for="(acItem, acIndex) in subItem.actions"
              :key="acIndex"
              class="action-item"
              type="text"
              size="small"
              @click.stop.prevent="
                actionClick(model, acItem.eventName, subItem.field)
              "
            >
              <img
                v-if="acItem.text === 'изменить' && readOnly"
                title="Редактировать"
                src="@/assets/images/icons/navigation/tasks.svg"
              >
              <img
                v-if="acItem.text === 'сохранить' && !readOnly"
                title="Сохранить"
                src="@/assets/images/icons/navigation/confirm.svg"
                :class="$style.saveIcon"
              >
              <img
                v-if="acItem.text === 'удалить' && readOnly"
                title="Удалить"
                src="@/assets/images/icons/navigation/trash.svg"
                :class="$style.deleteIcon"
              >
              <img
                v-if="acItem.text === 'отменить' && !readOnly"
                title="Отменить"
                src="@/assets/images/icons/navigation/cancel.svg"
                :class="[$style.deleteIcon, $style.saveIcon]"
              >
              <i
                :class="acItem.icon"
                v-html="acItem.formatter(model)"
              />
            </a>
          </div>
          <div v-else>
            <slot
              name="action"
              :row="model"
            />
          </div>
        </div>

        <div
          v-else-if="subItem.type === 'color'"
          :style="{
            height: '100%',
          }"
        >
          <div
            v-if="readOnly || model.type === 'item'"
            :style="{
              background: getColorString(model[subItem.field]),
              height: '100%',
              borderRadius: '5px',
            }"
          />
          <chrome
            v-if="!readOnly && model.type === 'folder'"
            v-model="color"
            :disable-alpha="true"
            class="rowColorPicker"
          />
        </div>
        <div v-else-if="subItem.type">
          <slot
            :name="subItem.type"
            :row="model"
          />
        </div>
        <div v-else>
          <div
            v-if="subItem.formatter"
            v-html="subItem.formatter(model)"
          />
          <div v-else>
            <input
              v-if="(subItem.field === 'caption' || subItem.field === 'comment') &&
                !readOnly &&
                model.type !== 'folder'
              "
              v-model="model[subItem.field]"
              type="text"
              :class="[
                $style.input,
                {
                  [$style.inactive]: readOnly,
                },
              ]"
            >
            <select
              v-else-if="subItem.field === 'actTypeName' &&
                !readOnly &&
                model.type !== 'folder'
              "
              v-model="model[subItem.field]"
              :class="$style.input"
              :disabled="readOnly"
            >
              <option
                v-for="option in options"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
            <div
              v-else
              :class="$style.basic"
            >
              {{ model[subItem.field] }}
            </div>
          </div>
        </div>
      </column>
      <div
        class="hover-model"
        style="display: none"
      >
        <div class="hover-block prev-block">
          <i class="el-icon-caret-top" />
        </div>
        <div class="hover-block center-block">
          <i class="el-icon-caret-right" />
        </div>
        <div class="hover-block next-block">
          <i class="el-icon-caret-bottom" />
        </div>
      </div>
    </div>
    <div v-if="isFolder">
      <row
        v-for="(item, index) in model[custom_field.lists]"
        v-show="model[custom_field.open]"
        :key="index"
        :model="item"
        :columns="columns"
        :isdraggable="isdraggable"
        :border="border"
        :depth="depth * 1 + 2"
        :custom_field="custom_field"
        :on-check="onCheck"
        :is-contain-children="isContainChildren"
        @menu="($event, model) => $emit('menu', $event, model)"
        @update="$emit('update')"
        @redirect="(model) => $emit('redirect', model)"
        @clickEvent="(model, eventName) => $emit('clickEvent', model, eventName)
        "
        @uncheckAll="$emit('uncheckAll')"
        @checkIfAllChecked="$emit('checkIfAllChecked')"
        @checkParent="(parent) => $emit('checkParent', parent)"
      >
        <template v-slot:selection="{ row }">
          <slot
            name="selection"
            :row="row"
          />
        </template>
        <template
          v-for="subItem in columns"
          v-slot:[subItem.type]="{ row }"
        >
          <slot
            :name="subItem.type"
            :row="row"
          />
        </template>
      </row>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import column from './column.vue';
import space from './space.vue';
import CheckMark from '@/components/common/filter/check-mark.vue';
import { Chrome } from 'vue-color';

export default {
  name: 'Row',
  components: {
    column,
    space,
    CheckMark,
    Chrome,
  },
  props: [
    'model',
    'depth',
    'columns',
    'isdraggable',
    'border',
    'custom_field',
    'onCheck',
    'isContainChildren',
  ],
  data() {
    return {
      open: false,
      visibility: 'visible',
      readOnly: true,
      name: '',
      options: [
        'Только оповещение',
        'Блокировать запуск',
        'Блокировать и оповещение',
      ],
      dangOptions: [
        { name: '1 уровень', id: 1 },
        { name: '2 уровень', id: 2 },
      ],
    };
  },
  computed: {
    isLeftGrid() {
      if (this.$route.name === 'remoteViewer' || this.$route.name === 'tasks') return true
      else return false
    },
    color: {
      get() {
        return this.getColor(this.model.color);
      },
      set(val) {
        this.model.color = this.colorToNumber(val.rgba);
      },
    },
    dangerousClass() {
      switch (this.model.dang) {
        case 0:
          return this.$style.green;
        case 1:
          return this.$style.yellow;
        case 2:
          return this.$style.red;
        default:
          return null;
      }
    },
    dangerousTitle() {
      switch (this.model.dang) {
        case 0:
          return 'Нарушения отсутствуют';
        case 1:
          return 'Есть нарушения 1-го уровня';
        case 2:
          return 'Есть нарушения 2-го уровня';
        default:
          return null;
      }
    },
    isFolder() {
      return (
        this.model[this.custom_field.lists] &&
        this.model[this.custom_field.lists].length
      );
    },
  },
  methods: {
    colorToNumber(color) {
      var u8 = new Uint8Array([color.r, color.g, color.b, 0]);
      var u32bytes = u8.buffer.slice(-4);
      var uint = new Uint32Array(u32bytes)[0];
      return uint;
    },
    getColorString(code) {
      let rgb = this.getColor(code);
      return `rgb(${rgb.r},${rgb.g},${rgb.b})`;
    },
    getColor(code) {
      let mask = 255;

      let r = code & mask;
      let g = (code >> 8) & mask;
      let b = (code >> 16) & mask;

      return { r, g, b };
    },
    maxWidth(item) {
      if (item.maxWidth) return item.maxWidth;
      else return '';
    },
    padding(item) {
      if (item.padding) return item.padding;
      else return '';
    },
    actionClick(model, eventName) {
      if (eventName === 'deleteItem')
        this.$emit('clickEvent', model, eventName);
      else if (eventName === 'changeName') {
        this.readOnly = false;
        this.name = model.title;
      } else if (eventName === 'saveNewName') {
        this.$emit('clickEvent', model, eventName);
        this.readOnly = true;
      } else {
        this.readOnly = true;
        if (model.ruleId) {
          this.$emit('update');
        } else model.title = this.name;
      }
    },
    toggle() {
      if (this.isFolder) {
        this.model[this.custom_field.open] =
          !this.model[this.custom_field.open];
        this.$forceUpdate();
      }
    },
    dragstart(e) {
      if (navigator.userAgent.indexOf('Firefox') >= 0) {
        // Firefox drag have a bug
        e.dataTransfer.setData('Text', this.id);
      }
      if (e.target.tagName === 'DIV') {
        window.dragId = e.target.children[0].getAttribute('tree-id');
        window.dragPId = e.target.children[0].getAttribute('tree-p-id');
        window.dragParentNode = e.target;
      }

      if (e.target.tagName === 'A') {
        window.dragId = e.target.getAttribute('tree-id');
        window.dragPId = e.target.getAttribute('tree-p-id');
        window.dragParentNode = e.target.parentNode;
      }
      e.target.style.opacity = 0.2;
    },
    dragend(e) {
      e.target.style.opacity = 1;
    },
    setAllCheckData(curList, flag) {
      const listKey = this.custom_field.lists;
      for (let i = 0; i < curList.length; i++) {
        const item = curList[i];
        if (item.checked !== flag) {
          this.$set(item, 'checked', flag);
          if (item.type !== 'folder') {
            if (item.ruleId) this.$store.commit('settings/addSecurity', item);
            else {
              if (this.$route.name === 'remoteViewer')
                this.$store.commit('vnc/addComputer', item);
            }
          }

        }
        if (item[listKey] && item[listKey].length) {
          this.setAllCheckData(item[listKey], flag);
        }
      }
    },
    onCheckboxClick(evt, model) {
      const list = model[this.custom_field.lists];
      // 判断是否有子节点，如有需递归处理
      if (list && this.isContainChildren) {
        this.setAllCheckData([model] || [], !!evt.target.checked);
      } else {
        this.$set(model, 'checked', !!evt.target.checked);
        if (model.ruleId) this.$store.commit('settings/addSecurity', model);
        else {
          if (this.$route.name === 'remoteViewer')
            this.$store.commit('vnc/addComputer', model);
        }
      }
      this.onCheck && this.onCheck();

      if (model.parentId > 0) this.$emit('checkParent', { parentId: model.parentId, wasChildChecked: evt.target.checked });

      if (!evt.target.checked) this.$emit('uncheckAll');
      else this.$emit('checkIfAllChecked');
    },
  },
};
</script>
<style lang="scss">
.tree-block {
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
}

.tree-row {
  position: relative;
  display: flex;
  //   padding: 10px 10px;
  border-bottom: 1px solid #eee;
  line-height: 30px;
  text-decoration: none;
  color: black;
  height: 55px;

  &:hover {
    background: #f5f5f5;
  }

  &.highlight-row {
    background: #ffe88c;
  }

  .align-left {
    text-align: left;
  }

  .align-right {
    text-align: right;
  }

  .align-center {
    text-align: center;
  }
}

.hover-model {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
}

.hover-block {
  display: flex;
  opacity: 0.1;
  transition: opacity 0.5s;
  justify-content: center;
  align-items: center;

  i {
    color: #fff;
  }
}

.prev-block {
  height: 25%;
  background: #a0c8f7;
}

.center-block {
  height: 50%;
  background: #a0c8f7;
}

.next-block {
  height: 25%;
  background: #a0c8f7;
}

.action-item {
  color: #2985bf !important;
  cursor: pointer;
  display: inline-flex;
  border-radius: 4px !important;

  i {
    font-style: normal;
  }
}

.zip-icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAf0lEQVQ4T7XT0Q2AMAhF0dvNdALdSEdzBB3BDXQD85LGRNMCauS7nAKBxMdIhfwemIAtYpeAEeiANoLUgAGYI4gFqAMX8QAXiQBCNFDNRBVdIgpUkSfADjT3KqLACmg/XrWw5J+Li+VVYCZrMBbgJluA+tXA3Hv45ZgiR3i+OQBeSyYRPEyeUAAAAABJRU5ErkJggg==') no-repeat center;
  background-size: cover;
}

.arrow-transparent {
  visibility: hidden;
}

.arrow-bottom {
  transform: rotate(90deg);
}

[draggable='true'] {
  -khtml-user-drag: element;
}
</style>

<style lang="scss" module>
.expandButton {
  flex-shrink: 0;
  margin-right: 13px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $branchColor;
  background: $white;
  // margin-top: 2px;

  &::before,
  &::after {
    content: '';
    background-color: $blue;
    width: 8px;
    height: 2px;
    position: absolute;
    transition: 0.25s ease-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &::after {
    transform: rotate(180deg);
  }

  &.expanded {
    &::before {
      transform: rotate(0);
    }

    &::after {
      transform: rotate(0);
    }
  }
}

.selection {
  display: inline-flex;
  width: 100%;
  white-space: nowrap;
  align-items: center;
  height: 30px;
}

.iconFolder {
  padding-right: 5px;
  padding-bottom: 3px;
  margin-top: 5px;
}

.invisButton {
  display: none;
}

.actionImgs {
  display: inline-flex;
  margin-left: 10px;
}

.inactive {
  border: 0px;
  background: transparent;
  cursor: pointer;
  width: 90%;
}

.inactive:focus {
  outline: none !important;
}

.saveIcon {
  width: 20px;
}

.deleteIcon {
  margin-left: 10px;
}

.routerLinkActive {
  padding: 0px;
  background-color: $light-gray;
}

.expandArea {
  cursor: pointer;
  display: flex;
  height: 30px;
  align-items: center;
  // height: 30px;
  // width: 30px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // padding: 22px;
}

.dangerous {
  flex: 0 0 12px;
  height: 12px;
  margin: 6px 0 6px 10px;
  border-radius: 50%;

  &.green {
    background: $green;
  }

  &.yellow {
    background: $yellow;
  }

  &.red {
    background: $red;
  }
}

.input {
  width: 100%;
}

.nameInput {
  width: 50%;
}

.noParentVncInput {
  margin-bottom: 5px;
}

.computerInput {
  width: 90%;
  font: inherit;
  font-size: 13px;
  height: 30px;
}

.basic {
  font-size: 13px;
  height: 30px;
  display: flex;
  align-items: center;
}
</style>

<style lang="scss">
.rowColorPicker {
  &.vc-chrome {
    width: 100%;
    height: 95%;
    margin: 10px 0px;
  }
}
</style>