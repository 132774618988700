<template>
  <div
    v-if="flex"
    class="tree-column"
    :class="{ border: border !== undefined }"
    :style="{
      width: width + 'px',
      flex: flex,
      maxWidth: maxWidth,
      padding: padding,
      whiteSpace: 'inherit',
      alignSelf: alignSelf,
    }"
  >
    <slot />
  </div>
  <div
    v-else
    class="tree-column"
    :class="{ border: border !== undefined }"
    :style="{ width: width + 'px' }"
  >
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Column',
  props: {
    width: Number,
    maxWidth: String,
    padding: String,
    field: String,
    label: String,
    flex: Number,
    border: String,
    alignSelf: {
      type: String,
      default: 'center',
    },
  },
  data() {
    return {
      open: false,
    };
  },
};
</script>
<style lang="scss">
.tree-column {
  position: relative;
  padding: 12px 12px;
  min-width: 30px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;

  //display: flex;
  // align-items: center;
  &.border {
    border-right: 1px solid #eee;
  }
}

.resize-line {
  position: absolute;
  top: 0;
  right: -3px;
  width: 6px;
  height: 100%;
  cursor: col-resize;
}
</style>
