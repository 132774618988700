<template>
  <div
    :class="$style.contextMenu"
    :style="{ left: mouseX + 'px', top: mouseY + 'px' }"
  >
    <div :class="$style.label">
      Переместить в:
    </div>
    <div
      v-for="group in groups"
      :key="group.id"
      :class="$style.link"
      @click="move(group)"
    >
      {{ group.groupName }}
    </div>
  </div>
</template>

<script>
import { changeGroup } from '@/api/methods/categories';

export default {
  props: ['mouseX', 'mouseY', 'groups', 'itemId'],
  methods: {
    async move(group) {
      await changeGroup(this.itemId, group.id);
      this.$store.dispatch('settings/refresh');
    },
  },
};
</script>

<style lang="scss" module>
.contextMenu {
  box-shadow: 4px 6px 14px rgba(165, 165, 165, 0.4);
  position: absolute;
  background-color: white;
  width: 244px;
  padding: 10px;
  z-index: 4;
}

.link {
  text-decoration: none;
  display: block;
  padding: 3px;
  color: #4b4b4b;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: $borderColor;
  }
}

.label {
  display: block;
  padding: 3px;
  color: #4b4b4b;
  font-size: 14px;
  font-weight: 500;
  cursor: default;
}
</style>
