import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import axios from '@/api/axios';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

function openToast(message, type) {
  Vue.$toast.open({
    message,
    type,
  });
}

function getSettings(link) {
  return axios.post(link).then((response) => {
    if (response.data.success) { return response; }

    openToast(response.data.message, 'error');
    response.data = null;

    return response;
  });
}

export function getCategoryList() {
  return getSettings('/category/categoryList');
}

export function renameGroup(id, name) {
  return axios.post(`/category/renameGroup/?id=${id}`, { name }).then((response) => {
    if (response.data.success) {
      openToast('Название категории успешно изменено', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function renameItem(id, name) {
  return axios.post(`/category/renameItem/?id=${id}`, { name }).then((response) => {
    if (response.data.success) {
      openToast('Изменения успешно сохранены', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function deleteGroup(id, type) {
  return axios.post(`/category/deleteGroup/?id=${id}&type=${type}`).then((response) => {
    if (response.data.success) {
      openToast('Группа успешно удалена', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function deleteItem(id) {
  return axios.post(`/category/deleteItem/?id=${id}`).then((response) => {
    if (response.data.success) {
      openToast('Элемент успешно удален', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function changeGroup(id, groupId) {
  return axios.post(`/category/changeGroup/?id=${id}&groupId=${groupId}`).then((response) => {
    if (response.data.success) {
      openToast('Элемент успешно перемещен', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function addItem(name, id, type) {
  return axios.post(`/category/addItem/?name=${name}&groupId=${id}`).then((response) => {
    if (response.data.success) {
      if (type === 1) { openToast('Программа успешно добавлена', 'success'); }
      if (type === 2) { openToast('Веб-сайт успешно добавлен', 'success'); }
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function addGroup(name, type, color) {
  return axios.post(`/category/addGroup/?name=${name}&type=${type}&color=${color}`).then((response) => {
    if (response.data.success) {
      openToast('Категория успешно добавлена', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function changeColor(id, color) {
  return axios.post(`/category/changeColor/?id=${id}&color=${color}`).then((response) => {
    if (response.data.success) {
      openToast('Цвет категории успешно изменен', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}
