var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.flex)?_c('div',{staticClass:"tree-column",class:{ border: _vm.border !== undefined },style:({
    width: _vm.width + 'px',
    flex: _vm.flex,
    maxWidth: _vm.maxWidth,
    padding: _vm.padding,
    whiteSpace: 'inherit',
    alignSelf: _vm.alignSelf,
  })},[_vm._t("default")],2):_c('div',{staticClass:"tree-column",class:{ border: _vm.border !== undefined },style:({ width: _vm.width + 'px' })},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }